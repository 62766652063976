import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";
import CommonHero from "../page_components/common/CommonHero";
import SecuritySection from "../page_components/common/SecuritySection";



const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Cookie Policy"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title="Cookie Policy"/>
                <div className={'container'}>
                    <p className="text-right pt-50">Last Update: 24 August 2022</p>

                    <div className={'text-justify pt-50'}>
                        <p className="py-3">Please read this policy carefully before using the website.</p>
                        <p className="py-3">The BitSpreader website uses cookies to&nbsp;distinguish you from other users of&nbsp;the
                            website. This helps BitSpreader to&nbsp;provide you with a&nbsp;more tailored experience
                            when you browse the website and also allows BitSpreader to&nbsp;improve the website and
                            services it&nbsp;provides. By&nbsp;continuing to&nbsp;use the BitSpreader website, you
                            are agreeing to&nbsp;the use of&nbsp;cookies. Unless context otherwise required,
                            a&nbsp;reference to&nbsp;the website shall be&nbsp;deemed to&nbsp;also refer
                            to&nbsp;a&nbsp;BitSpreader application.</p><p className="py-3">This cookie policy (“Cookie Policy”)
                        provides information about how and CodeFX&nbsp;Sp. z o.o.&nbsp;, registry code 0000883350, address Zamknieta 10/1.5, 30-554 Krakow, Poland (“we” or “us”) use cookies
                        when you visit the website <a className={'read-more-link'}
                            href="https://www.bitspreader.com/">https://www.bitspreader.com/</a> (“ Website”) or
                        access the services provided on the Website. For additional details about the
                        collection and use of personal data, please check our <a className={'read-more-link'}
                            href="/privacy">Privacy Policy</a>. If you do not accept this
                        Cookie Policy, please do not use our Website.</p>

                       <h2>1. What cookies are</h2>
                        <p className="py-3">Cookies are small text files placed on&nbsp;the browser or&nbsp;hard drive
                            of&nbsp;your computer, mobile, or&nbsp;other device when you visit a&nbsp;website.
                            We&nbsp;use cookies and similar technologies like web beacons, pixel tags,
                            or&nbsp;local shared objects («flash cookies»), to&nbsp;deliver, measure, and
                            improve our Services in&nbsp;various ways. A&nbsp;cookie is&nbsp;a&nbsp;small text
                            file that identifies your computer on&nbsp;our server. Cookies in&nbsp;themselves
                            do&nbsp;not identify the individual user, only the computer being used. Cookies are
                            not used to&nbsp;gather personal information.</p><p className="py-3">To find out more about cookies,
                        visit <a className="read-more-link" target="_blank" href="https://www.aboutcookies.org/" rel="noreferrer">this
                            site</a>.</p>

                       <h2>2. Use of cookies</h2><p className="py-3">Like most Internet sites, our Website
                        may use cookies and similar technologies like single-pixel gifs and web beacons.
                        We&nbsp;may use on&nbsp;our Website the following cookies:</p>
                        <ul className="list-style1">
                            <li>Strictly necessary cookies: these cookies are essential to&nbsp;enable you
                                to&nbsp;login, navigate around and use the features of&nbsp;BitSpreader website and
                                services, or&nbsp;to&nbsp;provide a&nbsp;service requested by&nbsp;you (such
                                as&nbsp;your username). BitSpreader does not need to&nbsp;obtain your consent
                                in&nbsp;order to&nbsp;use these cookies. These cookies can be&nbsp;used for
                                security and integrity reasons.
                            </li>
                            <li>Functionality cookies: These cookies allow BitSpreader services to&nbsp;remember
                                choices you make and provide enhanced and personalized features.
                            </li>
                            <li>Performance cookies: these cookies collect information about your online
                                activity (for example the duration of&nbsp;your visit on&nbsp;BitSpreader Services
                                and websites), including behavioral data and content engagement metrics. These
                                cookies are used for analytics, research and to&nbsp;perform statistics (based
                                on&nbsp;aggregated information).
                            </li>
                            <li>Analytical cookies that allow&nbsp;us to&nbsp;recognize and count the number
                                of&nbsp;visitors to&nbsp;our Website, to&nbsp;see how visitors move around the
                                Website when they are using it&nbsp;and to&nbsp;record which content viewers
                                view and are interested&nbsp;in. This helps&nbsp;us to&nbsp;improve the service
                                which we&nbsp;offer to&nbsp;you by&nbsp;helping&nbsp;us make sure our users are
                                finding the information they are looking for;
                            </li>
                            <li>Targeting cookies: these cookies are used to&nbsp;deliver tailored offers and
                                advertisements to&nbsp;you, based on&nbsp;your derived interests, as&nbsp;well
                                as&nbsp;to&nbsp;perform email marketing campaigns. They are usually placed
                                by&nbsp;advertisers (for example advertising networks) and provide them insights
                                about the people who see and interact with ads, visit their websites or&nbsp;use
                                their app.
                            </li>
                        </ul>
                        <p className="py-3">Currently, we use the following cookies:</p></div>

                                <table>
                                    <tr>
                                        <th width={"30%"}>Cookie</th>
                                        <th>Purpose</th>
                                    </tr>
                                    <tr>
                                        <td>Session tracking cookies</td>
                                        <td>These are first party cookies, which are both strictly necessary and
                                            functional. They gather information to&nbsp;maintain your login details
                                            throughout the session, avoiding re-authentication.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Site setting cookies</td>
                                        <td>These are first party cookies, which store temporary information
                                            to&nbsp;enable site functionality and collect and hold certain information
                                            which improves your experience on&nbsp;the Website,
                                            e.g.&nbsp;by&nbsp;setting you the appropriate language.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Google Analytics cookies</td>
                                        <td>These are third party analytical cookies which are offered by&nbsp;Google,
                                            that tracks and reports Website traffic. Google Analytics are used
                                            to&nbsp;collect information about how visitors use the Website, including
                                            details in&nbsp;respect of&nbsp;the number of&nbsp;visitors to&nbsp;the
                                            Website, where visitors have come to&nbsp;the Website from and the pages
                                            they visited. We&nbsp;use the information to&nbsp;compile reports about how
                                            visitors use Website and to&nbsp;help&nbsp;us improve the site. The cookies
                                            collect information in&nbsp;an&nbsp;anonymous form, (none of&nbsp;these
                                            cookies collect and/or store any personally identifiable information).
                                            In&nbsp;addition to&nbsp;ourselves, Google accesses the information
                                            collected by&nbsp;these cookies. Further details in&nbsp;respect
                                            of&nbsp;Google Analytics Cookie Usage can be&nbsp;found by&nbsp;found
                                            by&nbsp;<a className="read-more-link" target="_blank"
                                                       href="https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage"
                                                       rel="noreferrer">here</a>.
                                        </td>
                                    </tr>
                                </table>

                            <h2>3. Control of cookies</h2>
                                <p className="py-3">You can block cookies by&nbsp;activating the setting on&nbsp;your browser
                                    (or&nbsp;use the cookie setting in&nbsp;the lower left corner) that allows you
                                    to&nbsp;refuse the setting of&nbsp;all or&nbsp;some cookies. However, if&nbsp;you
                                    use your browser settings to&nbsp;block all cookies (including essential cookies)
                                    you may not be&nbsp;able to&nbsp;access all or&nbsp;parts of&nbsp;the BitSpreader
                                    website.</p><h2>4. Changes to this cookie policy</h2><p className="py-3">BitSpreader reserves the right
                                    to&nbsp;make changes to&nbsp;this Cookie Policy at&nbsp;any time and for any reason.
                                    BitSpreader will alert you about any changes by&nbsp;updating the «Last Updated» date
                                    of&nbsp;this Cookie Policy. Any changes or&nbsp;modifications will be&nbsp;effective
                                    immediately upon posting the updated Cookie Policy on&nbsp;the Site, and you waive
                                    the right to&nbsp;receive specific notice of&nbsp;each such change
                                    or&nbsp;modification.</p><p className="py-3">You are encouraged to&nbsp;periodically review this
                                    Cookie Policy to&nbsp;stay informed of&nbsp;updates. You will be&nbsp;deemed
                                    to&nbsp;have been made aware&nbsp;of, will be&nbsp;subject&nbsp;to, and will
                                    be&nbsp;deemed to&nbsp;have accepted the changes in&nbsp;any revised Cookie Policy
                                    by&nbsp;your continued use of&nbsp;the Site after the date such revised Cookie
                                    Policy is&nbsp;posted.</p><p className="py-3">This version was last updated on&nbsp;24.08.2022 and
                                    historic versions can be&nbsp;obtained by&nbsp;contacting&nbsp;us.</p><h2>5.
                                    Contact</h2><p className="py-3">For further information regarding the Cookie Policy, please contact us at <a className="read-more-link"
                                                                                                                                                 href="mailto:contact@bitspreader.com">contact@bitspreader.com</a> or by post:
                                    CodeFX&nbsp; Sp.z o.o. address: Zamknieta 10/1.5, 30-554, Krakow, Poland.
                                </p>

                </div>


                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
